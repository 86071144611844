import React, { useState } from 'react';

import styles from './ModalWindow.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { sendFormData } from '../../redux/slices/formSlice';

const ModalWindow = ({ isOpen, setIsOpen }) => {
  const { status, error } = useSelector(state => state.formSlice);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const dispatch = useDispatch();

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(sendFormData(formData)); // Correct dispatch of async thunk
  };

  const closeModal = () => setIsOpen(false);

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };
  return (
    <>
      {isOpen && (
        <div className={styles.modalOverlay} onClick={handleOverlayClick}>
          <div></div>
          <div
            className={styles.modalContainer}
            onClick={e => e.stopPropagation()}
          >
            <button className={styles.closeButton} onClick={closeModal}>
              X
            </button>
            <h2 className={styles.titleModal}>Contact Us</h2>
            <p className={styles.modalSubtitle}>
              Leave your details. We will answer all <br /> your questions.
            </p>
            <form onClick={handleSubmit} className={styles.modalForm}>
              <input
                type="text"
                placeholder="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={styles.modalInput}
                required
              />
              <input
                type="text"
                name="phone"
                placeholder="Phone number"
                value={formData.phone}
                onChange={handleChange}
                className={styles.modalInput}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
                className={styles.modalInput}
              />
              <textarea
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
                required
                className={styles.modalTextarea}
              ></textarea>
              <p className={styles.modalSubtitleBotton}>
                I agree with the processing of my personal data and the public
                offer agreement
              </p>
              <div className={styles.modalSubmit}>
                <div>
                  <button
                    type="submit"
                    disabled={status === 'loading'}
                    className={styles.button}
                  >
                    SEND
                  </button>
                  <div
                    className={styles.dot}
                    style={{ top: '10px', left: '10px' }}
                  ></div>
                  <div
                    className={styles.dot}
                    style={{ top: '10px', right: '10px' }}
                  ></div>
                  <div
                    className={styles.dot}
                    style={{ bottom: '10px', left: '10px' }}
                  ></div>
                  <div
                    className={styles.dot}
                    style={{ bottom: '10px', right: '10px' }}
                  ></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalWindow;
