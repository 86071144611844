import React, { useState } from 'react';
import styles from './Authorization.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import BtnAuth from '../BtnAuth';
import { useDispatch, useSelector } from 'react-redux';
import {
  accountsProfile,
  authSignIn,
  // refreshToken,
} from '../../../redux/slices/applicationSlice';
import {
  getAuthenticated,
  getProfile,
} from '../../../redux/slices/profileSlice';

const Authorization = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const error = useSelector(state => state.applicationSlice.error);
  const dispatch = useDispatch();

  const handleSetEmail = e => {
    setEmail(e.currentTarget.value);
  };
  const handleSetPassword = e => {
    setPassword(e.currentTarget.value);
  };

  const navigate = useNavigate();
  const handleSignIn = async e => {
    e.preventDefault();
    await dispatch(authSignIn({ email, password }))
      .then(({ payload }) => {
        if (payload.refresh) {
          navigate('/profile');
          dispatch(getProfile());
          // dispatch(getAuthenticated());
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div className={styles.wrapperAuthorization}>
      <div className={styles.titleDescr}>
        <h1 className={styles.titleAuthorization}>Login</h1>
      </div>
      <div className={styles.authorizationContainer}>
        <form onSubmit={handleSignIn} className={styles.form}>
          <div className={styles.inputGroup}>
            <label htmlFor="E-mail" className={styles.label}></label>
            <div>
              <input
                value={email}
                type="E-mail"
                id="E-mail"
                name="E-mail"
                placeholder="E-mail"
                className={styles.inputEmail}
                autoComplete="off"
                onChange={handleSetEmail}
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="password" className={styles.label}></label>
            <div>
              <input
                value={password}
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                className={styles.inputPassword}
                autoComplete="off"
                onChange={handleSetPassword}
              />
            </div>
          </div>
          <label className={styles.containerCheckbox} htmlFor="">
            <input type="checkbox" />
            <p className={styles.agreement}>Remember me</p>
          </label>
          <BtnAuth text="LOG IN" />

          {/* Отображение ошибки при неудачной авторизации */}
          {error && <div className={styles.error}>{error}</div>}
          <div className={styles.wrapperForgot}>
            <Link to="/reset" className={styles.passwordForgot}>
              Forgot password?
            </Link>
          </div>
          <div className={styles.noAccount}>
            No account?
            <Link to="/login" className={styles.linkRegister}>
              Register
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Authorization;
