import React, { useEffect, useRef, useState } from 'react';
import styles from './Header.module.scss';
import LogoWhite from '../../../assets/headerAsseets/LogoWhite';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector } from 'react-redux';
import ProfileDropdown from '../../../pages/DashBoardPage/ProfileDropdown/ProfileDropdown';
import Sidebar from '../Sidebar/Sidebar';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const user = useSelector(state => state.profileSlice.user);
  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  useEffect(() => {
    // Отключение прокрутки при открытом меню
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Очистка при размонтировании компонента
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  return (
    <div className={styles.mainHeader}>
      <header
        className={`${styles.header} ${showHeader ? styles.headerVisible : ''}`}
      >
        <div
          className={`${styles.containerHeader} ${isMenuOpen ? styles.containerMb : ''}  `}
        >
          <div
            className={`${styles.menuIcon} ${isMenuOpen ? styles.menuIconActive : ''} `}
            onClick={toggleMenu}
          >
            <MenuIcon />
          </div>
          <div className={styles.logoWrapper}>
            <Link to="/" className={styles['logo-link']}>
              <LogoWhite />
            </Link>
          </div>
          {isMenuOpen && (
            <Sidebar isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
          )}

          <button
            onClick={closeMenu}
            className={`${styles.closeButton} ${isMenuOpen ? styles.activeCloseButton : ''}`}
          >
            X
          </button>

          <div
            onClick={() => setIsOpen(!isOpen)}
            className={styles.wrapperNameImage}
          >
            <div
              className={`${styles.imageLogo} ${isMenuOpen ? styles.imageMob : ''}`}
            ></div>
          </div>
        </div>
        <div className={styles.borderBottomHeader}></div>
      </header>
      <ProfileDropdown isOpen={isOpen} dropdownRef={dropdownRef} user={user} />
    </div>
  );
};

export default Header;
