import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiInterceptors from '../../api/apiInterceptors';

const initialState = {
  tasks: [],
  error: null,
  loading: false,
};

export const addTaskSlice = createAsyncThunk(
  'tasks/addTask',
  async (formData, thunkAPI) => {
    try {
      const response = await apiInterceptors.post(
        'accounts/create_ticket/',
        formData,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getTaskSlice = createAsyncThunk(
  'tasks/getTask',

  async (_, { rejectWithValue, getState }) => {
    const state = getState();

    const user = state.profileSlice.user;
    const url =
      user?.role === 'Engineers' || user?.role === '3D modelers'
        ? 'get_workers_tickets'
        : 'get_tickets';
    try {
      const response = await apiInterceptors.get(`accounts/${url}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateTaskStatus = createAsyncThunk(
  'tasks/updateTaskStatus',
  async (ticketId, thunkAPI) => {
    try {
      const response = await apiInterceptors.post(
        `accounts/ticket_accept_or_decline/${ticketId}/`,
        { status: 'accepted' },
      );
      // Todo надо проверить почему мы так возвращаем
      return { ticketId, updatedTask: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const changeTaskStatus = createAsyncThunk(
  'tasks/changeTaskStatus',
  async ({ ticketId, newStatus }, thunkAPI) => {
    try {
      const response = await apiInterceptors.put(
        `/accounts/get_workers_ticket/${ticketId}/`,
        { status_for_worker: newStatus },
      );

      return { ticketId, updatedTask: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(addTaskSlice.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addTaskSlice.fulfilled, (state, action) => {
        state.loading = false;
        state.tasks.push(action.payload);
      })
      .addCase(addTaskSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getTaskSlice.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTaskSlice.fulfilled, (state, action) => {
        state.loading = false;
        state.tasks = action.payload;
      })
      .addCase(getTaskSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateTaskStatus.pending, state => {
        state.loading = true;
      })
      .addCase(updateTaskStatus.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateTaskStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(changeTaskStatus.pending, state => {
        state.loading = true;
      })
      .addCase(changeTaskStatus.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(changeTaskStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default taskSlice.reducer;
