import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import './styles.scss';
import './output.scss';

import ListDestinations from './pages/ListDestinations/ListDestinations';
import Layout from './Components/Layout/Layout';
import HomePage from './pages/HomePage';
import ModalWindow from './Components/modalWindow/ModalWindow';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Registration from './pages/Auth/Registration/Registration';
import Authorization from './pages/Auth/Authorization/Authorization';
import ResetPassword from './pages/Auth/ResetPassword/ResetPassword';
import NewPassword from './pages/Auth/NewPassword/NewPassword';
import DashboardLayout from './Components/DachBoard/DashBoardLatout/DashboardLayout';
import PrivatePage from './pages/PrivatePage/PrivatePage';
import MainPage from './pages/DashBoardPage/MainPage/MainPage';
import Confirmation from './pages/DashBoardPage/Сonfirmation/Confirmation';
import DigitalTwin from './pages/DashBoardPage/DigitalTwin/DigitalTwin';
import ProtectedRoute from './hooks/ProtectedRoute';
import MainBoard from './Components/DachBoard/MainBoard/MainBoard';
import ScrollToTop from './hooks/ScrollToTop';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const headerShowcaseRef = useRef(null);
  const whoAreRef = useRef(null);
  const greenRef = useRef(null);
  const digitalTwinInfoRef = useRef(null);
  const problemsRef = useRef(null);
  const sliderCarouselRef = useRef(null);
  const audienceProfilesRef = useRef(null);
  const technologylRef = useRef(null);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <Layout
              openModal={openModal}
              refs={{
                headerShowcaseRef,
                whoAreRef,
                greenRef,
                problemsRef,
                sliderCarouselRef,
                audienceProfilesRef,
                technologylRef,
              }}
            />
          }
        >
          <Route
            index
            element={
              <HomePage
                headerShowcaseRef={headerShowcaseRef}
                aboutUsRef={whoAreRef}
                greenRef={greenRef}
                digitalTwinInfoRef={digitalTwinInfoRef}
                problemsRef={problemsRef}
                technologylRef={technologylRef}
                sliderCarouselRef={sliderCarouselRef}
              />
            }
          />
          <Route path="destinations" element={<ListDestinations />} />
          <Route path="privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="login" element={<Registration />} />
          <Route path="authorization" element={<Authorization />} />
          <Route path="reset" element={<ResetPassword />} />
          <Route path="create" element={<NewPassword />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/profile" element={<DashboardLayout />}>
            <Route element={<PrivatePage />}>
              <Route path="" element={<MainPage />} />
              <Route
                path="digitaltwin"
                element={
                  <DigitalTwin
                    closeModal={closeModal}
                    isOpen={isOpen}
                    openModal={openModal}
                  />
                }
              />
              <Route path="confirmation" element={<Confirmation />} />
              <Route path="board/*" element={<MainBoard />} />
            </Route>
          </Route>
        </Route>
      </Routes>
      <ModalWindow isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
}

export default App;
