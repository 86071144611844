import React, { useEffect, useRef, useState } from 'react';

import styles from './SelectStatus.module.scss';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const checkboxInput = ['At work', 'Waiting', 'Completed'];

const SelectStatus = ({ currentTask, handleChangeStatus }) => {
  const [filter, setFilter] = useState('All'); // Используем это состояние для хранения выбранного статуса
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Ссылка на контейнер dropdown
  const toggleDropdown = e => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleCheckboxClick = async status => {
    setFilter(status); // Обновляем выбранный статус
    setIsOpen(false); // Закрываем дропдаун после выбора
    handleChangeStatus(status);
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.orderContentMb}>
      <div className={styles.statusAside}>Status</div>
      <div className={styles.contentIconStatus}>
        <div
          className={styles.container}
          onClick={toggleDropdown}
          ref={dropdownRef}
        >
          <div className={styles.selected}>
            <span>{filter}</span> {/* Отображаем выбранный статус */}
          </div>
          {isOpen && (
            <div className={styles.dropdown}>
              <ul className={styles.list}>
                {checkboxInput.map((status, index) => (
                  <li
                    onClick={() => handleCheckboxClick(status)}
                    key={index}
                    className={styles.listItem}
                  >
                    <div className={styles.wrapperNameCheckbox}>
                      <input
                        type="checkbox"
                        checked={filter === status}
                        readOnly
                      />
                      <span className={styles.nameCheckbox}>{status}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <ArrowForwardIosIcon className={styles.arrowIconAside} />
      </div>
    </div>
  );
};

export default SelectStatus;
