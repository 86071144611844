export const privacyPolicy = [
  {
    id: 1,
    section: '1. Information We Collect',
    description:
      'When you fill out the contact form on our website, we collect the following personal information:',
    items: [
      'Name',
      'Phone number',
      'Email address',
      'Message content (optional)',
    ],
  },
  {
    id: 2,
    section: '2. How We Use Your Information',
    description:
      'We use the information you provide for the following purposes:',
    items: [
      'To respond to your inquiries or requests.',
      'To provide customer support or follow-up communications.',
      'To improve our services based on your feedback.',
    ],
  },
  {
    id: 3,
    section: '3. How We Protect Your Information',
    description:
      'We take the security of your personal information seriously and implement the following measures:',
    items: [
      'Data encryption during transmission (SSL/TLS).',
      'Restricted access to your information, limited to authorized personnel only.',
      'Regular monitoring of our systems to prevent unauthorized access.',
    ],
  },
  {
    id: 4,
    section: '4. Information Sharing',
    description:
      'We do not sell, rent, or share your personal information with third parties, except in the following circumstances:',
    items: [
      'When required by law or in response to legal requests.',
      'To protect the rights, property, or safety of AIXLAND, our users, or others.',
    ],
  },
  {
    id: 5,
    section: '5. Data Retention',
    description:
      'We retain your personal information only as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law.',
  },
  {
    id: 6,
    section: '6. Your Rights',

    description: 'You have the right to:',
    items: [
      'Request access to the personal data we hold about you.',
      'Request corrections to any inaccurate or incomplete information.',
      'Request deletion of your personal data, subject to legal or contractual obligations.',
    ],
    subText: 'To exercise these rights, please contact us at:',
    contact: 'info@aixland.io',
  },
  {
    id: 7,
    section: '7. Cookies and Tracking',
    description:
      'Our website does not use cookies or tracking technologies to collect additional data beyond what you provide in the contact form.',
  },
  {
    id: 8,
    section: '8. Updates to This Privacy Policy',
    description:
      'We may update this Privacy Policy from time to time. Any changes will be posted on this page with the updated effective date.',
  },
  {
    id: 9,
    section: '9. Contact Us',
    description:
      'If you have any questions or concerns about this Privacy Policy or how your information is handled, please contact us at: ',
    contact: 'info@aixland.io',
    note: 'Thank you for trusting AIXLAND. We are committed to safeguarding your privacy.',
  },
];
