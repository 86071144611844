import axios from 'axios';
import { tokenLogout } from '../redux/slices/applicationSlice';

// Создаем экземпляр Axios с базовыми настройками
const apiInterceptors = axios.create({
  baseURL: 'https://aixland.io/api/',
  // baseURL: 'http://localhost/api/', // Базовый URL для всех запросов
  withCredentials: true, // Позволяет отправлять cookies с запросами (например, с токенами)
  timeout: 10000, // 10 секунд — тайм-аут для запросов
});

let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error); // Отклоняем все промисы в очереди с ошибкой
    } else {
      prom.resolve(token); // Разрешаем все промисы в очереди с новым токеном
    }
  });
  failedQueue = []; // Очищаем очередь после обработки
};

// Интерсептор для обработки ответа
apiInterceptors.interceptors.response.use(
  response => response, // Если ответ успешный, просто возвращаем его
  async error => {
    if (error.response.status === 401) {
      // store.dispatch(tokenLogout());
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

export default apiInterceptors; // Экспортируем экземпляр Axios с настроенными интерсепторами
