import React, { useCallback, useEffect, useState } from 'react';
import styles from './DigitalTwin.module.scss';
import Message from '../../../assets/dashboard/digitalAssets/Message';
import ModalDashBoard from '../../../Components/DachBoard/modalWindow/ModalDashBoard';
import ModalDigitalTwin from './ModalDigitalTwin/ModalDigitalTwin';
import { useDispatch, useSelector } from 'react-redux';
import { getTaskSlice } from '../../../redux/slices/taskSlice';
import { getWorkers } from '../../../redux/slices/profileSlice';
import TicketDetails from './TicketDetails/TicketDetails';

const DigitalTwin = () => {
  const [isOpen, setIsOpen] = useState(false);
  // const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const tasksStore = useSelector(state => state.taskSlice.tasks);
  const works = useSelector(state => state.workersSlice.workers);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTaskSlice());
  }, []);
  const getStatusClass = status => {
    switch (status) {
      case 'Waiting':
        return styles.waiting;
      case 'At work':
        return styles.atWork;
      case 'Completed':
        return styles.completed;

      default:
        return '';
    }
  };

  // const openSecondModal = task => {
  //   setIsSecondModalOpen(true);
  // };
  // const closeSecondModal = () => setIsSecondModalOpen(false);

  const openModal = useCallback(
    ticketId => {
      const task = tasksStore.find(task => task.ticket_id === ticketId);
      if (task) {
        setSelectedTask(task);
        setIsOpen(true); // Не забудьте открыть модальное окно
      }
    },
    [tasksStore],
  );

  const closeModal = () => setIsOpen(false);
  return (
    <div className={styles.wrapperDigital}>
      <ModalDashBoard isOpen={isOpen}>
        <TicketDetails
          selectedTask={selectedTask}
          openModal={openModal}
          closeModal={closeModal}
        />
      </ModalDashBoard>
      <h1 className={styles.title}>Services</h1>
      {tasksStore?.map(task =>
        task.ticket_info?.map(card => (
          <div
            onClick={() => openModal(task.ticket_id)}
            key={card.id}
            className={styles.cardSolarPanels}
          >
            <div className={styles.wrapperBlockCard}>
              <div>
                <div className={getStatusClass(task.status)}>{task.status}</div>
                <div className={styles.address}>address: {card.address}</div>
              </div>
              <div className={styles.responsibleId}>
                <div className={styles.responsibleName}>
                  <span>Responsible</span>:{' '}
                  {
                    task.assigned_to ||
                      'No responsible' /* Исправляем на task.assigned_to */
                  }
                </div>
              </div>
            </div>
            <div
              onClick={() => openModal(task.ticket_id)}
              className={styles.wrapperMessage}
            >
              <Message />
            </div>
          </div>
        )),
      )}
      {/*<ModalDashBoard isOpen={isOpen}>*/}
      {/*  <ModalDigitalTwin isOpen={isOpen} closeModal={closeModal} />*/}
      {/*</ModalDashBoard>*/}
    </div>
  );
};

export default DigitalTwin;
