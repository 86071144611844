import React, { useState } from 'react';
import solarPanels from '../../../assets/dashboard/productAssets/solarPanels.webp';
import configMebeli from '../../../assets/dashboard/productAssets/configuratorMebeli.webp';
import masterCare from '../../../assets/dashboard/productAssets/imageMasterCare.webp';

import styles from './MainPage.module.scss';
import BtnProfile from '../BtnProfile/BtnProfile';
import ModalDashBoard from '../../../Components/DachBoard/modalWindow/ModalDashBoard';
import PropertyModalContent from './PropertyModal/PropertyModalContent';
const product = [
  { id: 1, image: solarPanels, title: 'Solar panels', type: 'solar' },

  { id: 2, image: masterCare, title: 'Aixland MasterCare', type: 'work' },
  {
    id: 3,
    image: configMebeli,
    title: 'Сonfigurator furniture',
    type: 'furniture',
  },
];

const MainPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const openModal = product => {
    setSelectedProduct(product);
    setModalOpen(true);
  };
  const closeModal = () => {
    setSelectedProduct(null);
    setModalOpen(false);
  };
  return (
    <div>
      <h1 className={styles.titleProfile}>Services</h1>
      <ul className={styles.listProduct}>
        {product.map((item, index) => (
          <li key={index} className={styles.cardProduct}>
            <div>
              <div className={styles.containerImagePr}>
                <img className={styles.imageType} src={item.image} alt="" />
              </div>
              <div className={styles.productTitle}>{item.title}</div>
            </div>
            <div onClick={() => openModal(item)}>
              <BtnProfile title="TO ORDER" available={item.available} />
            </div>
          </li>
        ))}
      </ul>
      <ModalDashBoard isOpen={isModalOpen} onClose={closeModal}>
        <PropertyModalContent
          selectedProduct={selectedProduct}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      </ModalDashBoard>
    </div>
  );
};

export default MainPage;
