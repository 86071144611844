import React from 'react';
import styles from './ModalDashBoard.module.scss';
const ModalDashBoard = ({ isOpen, onClose, children, isSecondModalOpen }) => {
  if (!isOpen) return null;
  // if (!isSecondModalOpen) return null;
  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <button className={styles.modalClose} onClick={onClose}>
          ×
        </button>
        {children}
      </div>
    </div>
  );
};

export default ModalDashBoard;
