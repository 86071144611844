import React from 'react';

const Digital = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7407 1C18.1963 1 18.5657 1.36936 18.5657 1.82498V3.82583L21.1861 7.5665C21.5425 8.07512 21.7336 8.68105 21.7336 9.30204V21.35H22.175C22.6306 21.35 23 21.7194 23 22.175C23 22.6306 22.6306 23 22.175 23H1.825C1.36936 23 1 22.6306 1 22.175C1 21.7194 1.36936 21.35 1.825 21.35H2.26597V8.87728C2.26597 7.54841 3.58055 6.61923 4.83329 7.06264L10.6481 9.12077C11.4169 9.39288 11.9307 10.1199 11.9307 10.9354V21.35H13.7478V9.30204C13.7478 8.68105 13.939 8.07511 14.2952 7.56651L16.9157 3.82583V1.82498C16.9157 1.36936 17.2851 1 17.7407 1ZM17.7407 5.52392L15.6466 8.51316C15.4847 8.74434 15.3978 9.01977 15.3978 9.30204V21.35H16.9157V12.5497C16.9157 12.0941 17.2851 11.7247 17.7407 11.7247C18.1963 11.7247 18.5657 12.0941 18.5657 12.5497V21.35H20.0836V9.30204C20.0836 9.01977 19.9967 8.74435 19.8347 8.51316L17.7407 5.52392ZM10.2807 21.35V10.9354C10.2807 10.8189 10.2073 10.7151 10.0975 10.6761L4.28272 8.61804C4.10377 8.5547 3.91597 8.68744 3.91597 8.87728V21.35H10.2807ZM5.03262 13.6415C5.03262 13.1859 5.40199 12.8166 5.85762 12.8166H8.05762C8.51326 12.8166 8.88262 13.1859 8.88262 13.6415C8.88262 14.0971 8.51326 14.4665 8.05762 14.4665H5.85762C5.40199 14.4665 5.03262 14.0971 5.03262 13.6415ZM5.03262 16.9597C5.03262 16.5041 5.40199 16.1347 5.85762 16.1347H8.05762C8.51326 16.1347 8.88262 16.5041 8.88262 16.9597C8.88262 17.4153 8.51326 17.7847 8.05762 17.7847H5.85762C5.40199 17.7847 5.03262 17.4153 5.03262 16.9597Z"
        fill="white"
      />
    </svg>
  );
};

export default Digital;
