import Cookies from 'js-cookie';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiInterceptors from '../../api/apiInterceptors';
import axios from 'axios';

const initialState = {
  error: null,
  signingUp: false,
  signingIn: false,
  token: Cookies.get('token'),
};

export const authSignUp = createAsyncThunk(
  'auth/signup',
  async ({ name, email, password, password2 }, thunkAPI) => {
    try {
      await apiInterceptors.post('accounts/signup/', {
        password2,
        password,
        email,
        username: name,
      });
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const authSignIn = createAsyncThunk(
  'auth/signin',
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await apiInterceptors.post('accounts/login/', {
        email,
        password,
      });
      // Todo Нужно подумать над оптимазацией кода, если есть возможность проверять есть ли у пользователя токен или нет
      //  сохранен ли токен
      const token = response.data.access;
      Cookies.set('token', token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || 'Authorization error',
      );
    }
  },
);

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    tokenLogout: state => {
      Cookies.remove('token');
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(authSignUp.pending, state => {
        state.signingUp = true;
        state.error = null;
      })
      .addCase(authSignUp.rejected, (state, action) => {
        state.signingUp = false;
        if (action.payload?.response?.data) {
          state.error = action.payload.response.data;
        }
        console.log(action.payload, 'extra');
      })
      .addCase(authSignUp.fulfilled, state => {
        state.signingUp = false;
        state.error = null;
      })
      .addCase(authSignIn.pending, state => {
        state.signingIn = true;
        state.error = null;
      })
      .addCase(authSignIn.rejected, (state, action) => {
        state.signingIn = false;
        state.error = action.payload;
      })
      .addCase(authSignIn.fulfilled, (state, action) => {
        state.signingIn = false;
        state.error = null;
        state.token = action.payload;
      });
  },
});

export const { tokenLogout } = applicationSlice.actions;

export default applicationSlice.reducer;
