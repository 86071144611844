import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiInterceptors from '../../api/apiInterceptors';

const initialState = {
  user: null,
  loading: false,
  error: null,
  isAuthenticated: false,
};

export const getProfile = createAsyncThunk(
  'auth/getProfile',
  async (_, thunkAPI) => {
    const response = await apiInterceptors.get('accounts/profile/', {
      withCredentials: true,
    });
    return response.data;
  },
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    clearProfile(state) {
      state.user = null; // Очистить данные профиля
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getProfile.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });

    // .addCase(getAuthenticated.pending, state => {
    //   state.loading = true;
    //   state.error = null;
    // })
    // .addCase(getAuthenticated.fulfilled, (state, action) => {
    //   // console.log(action.payload);
    //   state.loading = false;
    //   state.isAuthenticated = action.payload;
    // })
    // .addCase(getAuthenticated.rejected, (state, action) => {
    //   state.error = action.payload;
    //   state.loading = false;
    // });
  },
});

export const { clearProfile } = profileSlice.actions;

export default profileSlice.reducer;
