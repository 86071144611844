import React from 'react';
import styles from './DataVerification.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import BtnProfile from '../../../BtnProfile/BtnProfile';
import { useDispatch } from 'react-redux';
import {
  getTaskSlice,
  updateTaskStatus,
} from '../../../../../redux/slices/taskSlice';

const DataVerification = ({ onCloseByFocusLost, onClose, selectedTask }) => {
  const dispatch = useDispatch();
  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      onCloseByFocusLost();
    }
  };

  const ticketId = selectedTask.ticket_id;
  const handleAccepted = async () => {
    try {
      await dispatch(updateTaskStatus(ticketId)).then(() => {
        dispatch(getTaskSlice());
      });
      onCloseByFocusLost();
    } catch (error) {
      console.error('Ошибка при обновлении статуса:', error);
    }
  };

  const workType = selectedTask?.ticket_info?.[0]?.work_type;
  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modalContainer}>
        <div onClick={onCloseByFocusLost} className={styles.backLink}>
          <CloseIcon />
        </div>
        <h1 className={styles.titleModal}>Checking</h1>
        <div className={styles.borderBottom}></div>
        <div>
          {selectedTask?.ticket_info?.map(item => (
            <div key={item.id} className={styles.dataBlock}>
              <div className={styles.dataItem}>
                <strong>Phone number:</strong>
                <span className={styles.blockSpan}>{item.phone}</span>
              </div>
              <div className={styles.dataItem}>
                <strong>Address:</strong>
                <span className={styles.blockSpan}>{item.address}</span>
              </div>

              <div className={styles.dataItem}>
                <strong>Type of property:</strong>
                <span className={styles.blockSpan}>{item.building_type}</span>
              </div>
              {workType && (
                <div className={styles.dataItem}>
                  <strong>Type of work:</strong>
                  <span className={styles.blockSpan}>{item.work_type}</span>
                </div>
              )}
              <div className={styles.dataItem}>
                <strong>Description:</strong>
                <span className={`${styles.blockSpan} ${styles.spanComment}`}>
                  {item.comment}{' '}
                </span>
              </div>
              <div className={styles.borderBottomTwo}></div>
              <div className={styles.dataInputPlan}>
                <div>Roof plan file</div>
                <div className={styles.blockInput}>
                  <span>Roof plan</span>
                  <SaveAltIcon className={styles.icon} />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.blockBtn}>
          <div className={styles.btn}>
            <BtnProfile
              handleAccepted={handleAccepted}
              margin=" 0"
              colorClass="#000000"
              title="ACCEPT"
            />
          </div>
          <div className={styles.btn}>
            <BtnProfile onClose={onClose} margin="0" title="REPORT A BUG" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataVerification;
