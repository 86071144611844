import React, { memo } from 'react';

import styles from './Header.module.scss';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
const dots = [
  { class: `${styles.dot} ${styles['top-left']}` },
  { class: `${styles.dot} ${styles['top-right']}` },
  { class: `${styles.dot} ${styles['bottom-left']}` },
  { class: `${styles.dot} ${styles['bottom-right']}` },
];
const BtnHeader = ({
  openModal,
  isMenuOpen,
  title,
  backgroundColor,
  color,
  marginRight,
  dot,
  path,
  closeMenu,
}) => {
  const stylesBtn = {
    backgroundColor: backgroundColor || 'white',
    color: color || 'black',
    marginRight: marginRight || 0,
  };

  const stylesDot = {
    backgroundColor: dot || 'black',
  };

  const titleHover = title === 'PROFILE' ? styles.buttonOrange : '';
  return (
    <div
      className={`${styles.btnHeader} ${isMenuOpen ? styles.btnActive : ''}`}
    >
      <div className={styles.buttonContainer} style={stylesBtn}>
        <Link to={path}>
          <button
            onClick={closeMenu ? closeMenu : openModal}
            className={`${styles.button} ${titleHover}`}
          >
            {title}
          </button>
        </Link>
        {dots.map((dot, index) => (
          <div key={index} className={dot.class} style={stylesDot}></div>
        ))}
      </div>
    </div>
  );
};

export default memo(BtnHeader);
