import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';

import logoShowCase from '../../assets/headerAsseets/Logo2.webp';

import styles from './HeaderShowcase.module.scss';
import Btn from './Btn';

const HeaderShowcase = ({ headerShowcaseRef, openModal }) => {
  const logoRef = useRef(null);

  useEffect(() => {
    // Устанавливаем начальный размер 0
    gsap.set(logoRef.current, {
      scale: 0,
    });

    // Запускаем анимацию увеличения размера
    gsap.to(logoRef.current, {
      scale: 1,
      duration: 1, // продолжительность анимации
      ease: 'power3.inOut', // тип плавности
    });
  }, []);

  return (
    <div ref={headerShowcaseRef} className={styles.containerShowCase}>
      <div className={` ${styles.logoBtnCase}`}>
        <img
          ref={logoRef}
          src={logoShowCase}
          alt="Logo"
          className={styles.logoImage}
        />
        {/*<Btn openModal={openModal} />*/}
      </div>
    </div>
  );
};

export default HeaderShowcase;
