import React from 'react';
import styles from './ProfileDropdown.module.scss';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { tokenLogout } from '../../../redux/slices/applicationSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const block = [
  { id: 1, title: 'Setting', icon: <SettingsIcon /> },
  { id: 2, title: 'Exit', icon: <LogoutIcon />, onClick: tokenLogout },
];

const ProfileDropdown = ({ user, dropdownRef, isOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(tokenLogout());
  };
  const handleItemClick = id => {
    if (id === 2) {
      handleLogout();
      navigate('/authorization');
      // Логика для блока "Exit"
    }
    // Добавьте логику для других элементов, если нужно
  };
  return (
    <>
      {isOpen && (
        <div ref={dropdownRef} className={styles.mainProfileDropdown}>
          <div className={styles.contentDropdown}>
            <div className={styles.contentProfile}>
              <div className={styles.avatarProfile}></div>
              <div className={styles.userName}>
                {user ? user.username : 'User'}
              </div>
              <div></div>
            </div>
            <div className={styles.borderBottom}></div>
            <div>
              <div className={styles.mainSetting}>
                {block.map((item, index) => (
                  <div
                    onClick={() => handleItemClick(item.id)}
                    key={index}
                    className={styles.iconTitle}
                  >
                    <div>{item.icon}</div>
                    <p>{item.title}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileDropdown;
