import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerTitle}>All rights reserved</div>
      <Link to="/privacyPolicy">
        <div className={styles.footerTitle}>Privacy Policy</div>
      </Link>

      <div className={styles.footerTitle}>2025 AIXLAND</div>
    </footer>
  );
};

export default Footer;
