import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addTaskSlice } from './taskSlice';
import apiInterceptors from '../../api/apiInterceptors';

const initialState = {
  error: null,
  loading: false,
  task: {},
};

export const getTask = createAsyncThunk(
  'task/getTask',
  async ({ ticketId }, thunkAPI) => {
    try {
      const response = await apiInterceptors.get(
        `accounts/get_workers_ticket/${ticketId}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const taskWorkerSLice = createSlice({
  name: 'taskWorkerSlice',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getTask.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTask.fulfilled, (state, action) => {
        state.loading = false;
        state.task = action.payload; // Сохраняем данные о задаче
      })
      .addCase(getTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Сохраняем ошибку
      });
  },
});

export default taskWorkerSLice.reducer;
