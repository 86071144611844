import React, { memo } from 'react';
import styles from './Tasks.module.scss';
import Message from '../../assets/dashboard/digitalAssets/Message';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Tasks = memo(
  ({
    tasksStore,
    children,
    openCheckingModal,
    minHeight,
    margin,
    width,
    onOpen,
    pathTo,
  }) => {
    const getStatusClass = status => {
      switch (status) {
        case 'Waiting':
          return styles.waiting;
        case 'At work':
          return styles.atWork;
        case 'Completed':
          return styles.completed;

        default:
          return '';
      }
    };
    const user = useSelector(state => state.profileSlice.user);
    const styleBlock = {
      minHeight: minHeight || '305px',
      marginTop: margin,
      maxWidth: width || '500px',
    };

    const urlRole =
      user?.role === 'Engineers'
        ? tasksStore.status_for_worker
        : tasksStore.status;

    return (
      <div style={styleBlock} className={styles.cardSolarPanels}>
        <div className={styles.flexCardTask}>
          {tasksStore?.ticket_info?.map(ticket => (
            <div
              key={ticket.id}
              {...(onOpen && { onClick: () => onOpen(tasksStore.ticket_id) })}
              className={`${styles.wrapperBlockCard} `}
            >
              <div>
                <div className={getStatusClass(urlRole)}>{urlRole}</div>
                <div className={styles.address}>address: {ticket.address}</div>
              </div>
              <div className={styles.responsibleId}>
                <div className={styles.id}></div>
                <div className={styles.responsibleName}>
                  <span>Responsible</span>: {user ? user.username : 'User'}
                </div>
              </div>
            </div>
          ))}

          <div className={styles.wrapperMessage}>
            <Message />
            {pathTo && (
              <Link to={pathTo}>
                <div aria-disabled={false} className={styles.iconTask}>
                  <ArrowForwardIosIcon className={styles.iconArrow} />
                </div>
              </Link>
            )}
            {/*to={tasksStore.completed ? 'finishedWorks' : ''}*/}
          </div>
        </div>

        <div onClick={openCheckingModal} className={styles.btn}>
          {children}
        </div>
      </div>
    );
  },
);

export default Tasks;
