import React, { useState } from 'react';
import Star123 from '../../assets/technologyAssets/icon/Star 123';
import imageBorder from '../../assets/technologyAssets/icon/download (29).webp';
import imageLogo from '../../assets/technologyAssets/icon/Слой 1.svg';
import videoWorld from '../../assets/technologyAssets/video/GreenSphere.webm';
import videoWorldMov from '../../assets/technologyAssets/video/GreenSphere-2.mov';
import styles from './Technology.module.scss';
import CardTechnology from './CardTechnology';
import BorderLine from './BorderLine';
import BorderDirect from './BorderDirect';

const technology = [
  {id:1, title: 'Green Blockchains:', imageBorder:imageBorder, Animation:BorderLine, ImageRomb:Star123,text: 'Secure Data. Management Ensures data security while minimizing environmental impact.'},
  {id:2, title: 'Smart contracts:', imageBorder:imageBorder, Animation:BorderDirect,ImageRomb:Star123 ,text: 'Automated Document. Management Streamlines rental and service agreements, enhancing transparency.'},
  {id:3, title: 'AI:', imageBorder:imageBorder,Animation:BorderLine, ImageRomb:Star123,text: 'Market Forecasting. Analyzes trends to optimize maintenance and identify issues.'},
  {id:4, imageLogo:imageLogo,videoWorld:videoWorld,videoWorldMow:videoWorldMov, completed:true, subText: 'def generate_random_string(length): chars = string.ascii_letters + string.digits + string.punctuation return  '  },
  {id:5, title: 'Virtual Reality Experiences:', imageBorder:imageBorder, Animation:BorderLine, ImageRomb:Star123,text: 'Interactive Property. Tours Immersive 3D experiences for informed decision-making.'},
  {id:6, title: 'DeFi:', imageBorder:imageBorder, Animation:BorderDirect, ImageRomb:Star123,text:'Decentralized Financing. Innovative financing options beyond traditional banks.'},
  {id:7, title:'Ion:', imageBorder:imageBorder,Animation:BorderLine, ImageRomb:Star123,text: 'Real-Time Monitoring. 3D models with IoN for continuous property management.'},
]

const Technology = ({technologylRef}) => {
  const [hovered, setHovered] = useState(false);
  //useCallback
  const onMouseEnter = (index) => {
    if (index === 3) {
      setHovered(true);
    }
  };

   const onMouseLeave = (index) => {
      if (index === 3) {
      setHovered(false);
    }
  };

  const [isTransparent, setTransparent] = React.useState(false);

  const toggleTransparency = () => {
    setTransparent((prev) => !prev);
  };

  return (
    <div ref={technologylRef} className={`${styles.blackBack} ${isTransparent ? styles.transparent : ''}`}>
       <h1 className={styles.titleTechn} >Technology</h1>
      <div className={styles.technologyGrid}>
        {technology.map((item,index) => (
          <CardTechnology hovered={hovered} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}  index={index} key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
};

export default Technology;