import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiInterceptors from '../../api/apiInterceptors';

const initialState = {
  comments: [],
  loading: false,
  error: false,
};

export const addComment = createAsyncThunk(
  'comments/addComment',
  async ({ ticketId, comment }, thunkAPI) => {
    try {
      const response = await apiInterceptors.post(
        `accounts/worker_create_response/${ticketId}/`,
        { comment },
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const deleteComment = createAsyncThunk(
  'comments/delete_comment',
  async ({ ticketId, id }, thunkAPI) => {
    try {
      const response = await apiInterceptors.delete(
        `accounts/update_delete_comment/${ticketId}/`,
        {
          data: {
            comment_id: id,
          },
        },
      );

      return response.data;
    } catch (error) {
      console.error('Error deleting comment:', error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

const commentSlice = createSlice({
  name: 'comments',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(addComment.pending, state => {
        state.loading = true;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.loading = false;
        state.comments = action.payload;
      })
      .addCase(addComment.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(deleteComment.pending, state => {
        state.loading = true;
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        state.loading = false;
        // Todo проверить обязательно
        if (Array.isArray(state.comments)) {
          state.comments = state.comments.filter(
            comment => comment.id !== action.payload.id,
          );
        }
      })
      .addCase(deleteComment.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default commentSlice.reducer;
